
.button-footer{
    background-color: #ffa600;
    color: black;
    border: none;
}

.button-footer:hover{
    background-color: white;
    color: black;
}

